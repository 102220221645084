import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import ClearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import './LookupMaintenance.scss'
import axios from 'axios';
import { withUserContext } from "../../../contexts/UserContext";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";

class OnflyLookup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            affilationOptions: [],
            writersDetails: null,
            cueSheetPubDetails:null,
            performerDetails:null,
        };
    }

    componentDidMount() {
        if (this.props?.type === 'writer') {
            this.getAffilation();
            
        }
        if (this.props?.type === 'cueSheetPublisher') {
            if (this.props?.cueSheetPubId !== 'new') {
                this.getCueSheetData(this.props?.cueSheetPubId)
            }
        }
        if (this.props?.type === 'performer') {
            if (this.props?.performerId !== 'new') {
                this.getPerformerData(this.props?.performerId)
            }
        }
    }

    // getWriterData = (id) => {
    //     this.setState({ isFetchingData: true })
    //     ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
    //         `/entitySearch?entity=WRITER&searchString=${id?.text}`, this.props.userContext?.active_tenant?.tenant_id)
    //         .then(response => {
    //             let  writerData = response.data?.find(item => (item?.writer_id == id?.value && item?.song_id == this.props?.songDetails?.song_id));
    //             this.setState({ writersDetails: writerData, isFetchingData: false });
    //         },
    //             (err) => {
    //                 console.log("Error in fetching Address Types:", err);
    //                 this.setState({ writersDetails: null, isFetchingData: false });
    //             })
    // }

    getCueSheetData = (id) => {
        this.setState({ isFetchingData: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=lu_cue_sheet_publisher`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let cueSheetData = response.data?.find(item => item?.id == id);
                this.setState({ cueSheetPubDetails: cueSheetData, isFetchingData: false });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ cueSheetPubDetails: null, isFetchingData: false });
                })
    }

    getPerformerData = (id) => {
        this.setState({ isFetchingData: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=lu_performer`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let performerData = response.data?.find(item => item?.id == id);
                this.setState({ performerDetails: performerData, isFetchingData: false });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ performerDetails: null, isFetchingData: false });
                })
    }

    handleWriterSubmit = () => {
        this.setState({ postInitiated: true });
        if (this.state.writersDetails?.writer_name) {
            this.setState({ postInitiated: false,isSubmit: true });
            let writerData = {
                ...this.state.writersDetails,
                is_active: 1,
            };
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/writer`, writerData, this.props?.userContext?.active_tenant?.tenant_id
            ).then((response) => {
                let writerData = {
                    value: response?.data[0]?.writer_id,
                    text: `${this.state.writersDetails?.writer_name}${this.state.affilationOptions?.find(item => item?.value === this.state.writersDetails?.affiliation_id)?.label !=undefined ? " ("+this.state.affilationOptions?.find(item => item?.value === this.state.writersDetails?.affiliation_id)?.label+")" :""} ${(this.state.writersDetails?.share_percentage !=undefined && this.state.writersDetails?.share_percentage !=0) ? this.state.writersDetails?.share_percentage:""}`,
                    temp_text: `${this.state.writersDetails?.writer_name}${this.state.affilationOptions?.find(item => item?.value === this.state.writersDetails?.affiliation_id)?.label !=undefined ? "-"+this.state.affilationOptions?.find(item => item?.value === this.state.writersDetails?.affiliation_id)?.label :""} ${(this.state.writersDetails?.share_percentage !=undefined && this.state.writersDetails?.share_percentage !=0) ? this.state.writersDetails?.share_percentage:""}`
                }
                this.props.handleAddWriter(writerData)
                this.setState({isSubmit: false})
            },
                (err) => {
                     this.setState({ isSubmit: false });
                }
            );
        }
    };

    postCueSheetPub = () => {
        this.setState({ postInitiated: true })
        if (this.state.cueSheetPubDetails?.name) {
            this.setState({ postInitiated: false, isSubmit:true });
            let cuesheetPublisherData = { ...this.state.cueSheetPubDetails, is_active: 1, table_name: "lu_cue_sheet_publisher" }
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
                cuesheetPublisherData,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(
                    (response) => {
                        let cueSheetPubData = {
                            value: response?.data[0]?.id,
                            text: `${this.state.cueSheetPubDetails?.name}`
                        }
                        this.props.handleCueSheetPubUpdate(cueSheetPubData)
                        this.setState({isSubmit: false})
                    },
                    (err) => {
                        this.setState({ isSubmit: false });
                    }
                )
        }
    };

    postPerformer = () => {
        this.setState({ postInitiated: true })
        if (this.state.performerDetails?.name) {
            this.setState({ postInitiated: false, isSubmit:true });
            let performerData = { ...this.state.performerDetails, is_active: 1, table_name: "lu_performer" }
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
                performerData,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(
                    (response) => {
                        let performerData = {
                            value: response?.data[0]?.id,
                            text: `${this.state.performerDetails?.name}`
                        }
                        this.props.handlePerformerUpdate(performerData)
                        this.setState({isSubmit: false})
                    },
                    (err) => {
                        this.setState({ isSubmit: false });
                    }
                )
        }
    };


    getAffilation = () => {
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl +
            "/staticData?staticTable=lu_affiliation",
            this.props?.userContext?.active_tenant?.tenant_id
        ).then((response) => {
            let useList = response?.data?.map((item) => ({
                label: item.name,
                value: item.id,
                is_active: item.is_active,
            }));
            if (this.props?.writerId !== 'new') {
                let textAfterWriterName =this.props?.writerId?.text?.split('-')[1];
                let affiliationName = textAfterWriterName?.substring(0, textAfterWriterName?.lastIndexOf(' '));
                let share =this.props?.writerId?.text?.split(' ').reverse()[0];
                let writerdetails ={
                    writer_id: this.props?.writerId?.value,
                    writer_name: (this.props?.writerId?.text?.includes('-')) ? (this.props?.writerId?.text.split('-')[0]):(this.props?.writerId?.text),
                    affiliation_id : useList?.find(item => item.label == affiliationName)?.value,
                    share_percentage : share ? parseFloat(share):0,
                }
                this.setState({writersDetails : writerdetails})
            }
            this.setState({ affilationOptions: useList });
        });
    };

    handleEdit = (field, value, stateName) => {
        this.setState((prevState) => ({
            [stateName]: {
                ...prevState[stateName],
                [field]: value,
            },
            postInitiated: false,
        }));
    };

    render() {
        return (
            <>
                {this.state.isFetchingData ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                     <div>
                     {this.props?.type === 'writer' && <div className="OnFlyLookUP">
                         <MDBRow>
                             <MDBCol md={4}>
                                 <BasicTextField
                                     label="Writer Name"
                                     isMandatory={true}
                                     showMandatory={this.state.postInitiated || false}
                                     placeholder={"- Writer Name -"}
                                     value={this.state.writersDetails?.writer_name}
                                     disabled= {(this.state.writersDetails?.writer_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor'))}
                                     onChange={(e) =>
                                         this.handleEdit(
                                             "writer_name",
                                             e.target.value.length > 0 ? e.target.value : null,'writersDetails'
                                         )
                                     }
                                 />
                             </MDBCol>
                             <MDBCol md={4}>
                                 <SelectField
                                     id="affilation"
                                     label="Affiliation"
                                     placeholder={"-Affilation -"}
                                     isMandatory={false}
                                     value={this.state.writersDetails?.affiliation_id || null}
                                     options={this.state.affilationOptions || []}
                                     onChange={(e) =>
                                         this.handleEdit("affiliation_id", e.target.value,'writersDetails')
                                     }
                                 />
                             </MDBCol>
                            
                             <MDBCol md={4}>
                             <BasicTextField
                             id="share"
                             label="Share"
                             isMandatory={false}
                             placeholder={"__.__"}
                             value={this.state.writersDetails?.share_percentage || null}
                             onChange={(e)=>
                                this.handleEdit("share_percentage",e.target.value.length > 0 ? e.target.value : null,'writersDetails')
                            }
                             />
                         </MDBCol>
                         </MDBRow>
                     </div>}
                     {this.props?.type === 'cueSheetPublisher' && <div className="OnFlyLookUP">
                         <MDBRow>
                             <MDBCol md={8}>
                                 <BasicTextField
                                     label="Cue Sheet Publisher Name"
                                     isMandatory={true}
                                     showMandatory={this.state.postInitiated || false}
                                     placeholder={"- Cue Sheet Publisher Name -"}
                                     value={this.state.cueSheetPubDetails?.name}
                                     onChange={(e) =>
                                         this.handleEdit(
                                             "name",
                                             e.target.value.length > 0 ? e.target.value : null,'cueSheetPubDetails'
                                         )
                                     }
                                 />
                             </MDBCol>
                         </MDBRow>
                     </div>}
                     {this.props?.type === 'performer' && <div className="OnFlyLookUP">
                         <MDBRow>
                             <MDBCol md={8}>
                                 <BasicTextField
                                     label="Performer Name"
                                     isMandatory={true}
                                     showMandatory={this.state.postInitiated || false}
                                     placeholder={"- performer Name -"}
                                     value={this.state.performerDetails?.name}
                                     disabled={(this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name !== 'Music Clearance (Admin)'))}
                                     onChange={(e) =>
                                         this.handleEdit(
                                             "name",
                                             e.target.value.length > 0 ? e.target.value : null,'performerDetails'
                                         )
                                     }
                                 />
                             </MDBCol>
                         </MDBRow>
                     </div>}
                     <MDBRow>
                         <MDBRow className="p-2">
                             <MDBCol md={6}></MDBCol>
                             <MDBCol md={2}>
                                 <BasicButton
                                     variant="contained"
                                     type="inline"
                                     text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                     onClick={() => {
                                         if(this.props?.type === 'writer') {
                                             this.handleWriterSubmit()
                                         }
                                         if(this.props?.type === 'cueSheetPublisher') {
                                            this.postCueSheetPub()
                                         }
                                         if(this.props?.type === 'performer') {
                                            this.postPerformer()
                                         }
                                     }}
                                 />
                             </MDBCol>
                             <MDBCol md={2} >
                                 <BasicButton
                                     type="inline"
                                     variant="outlined"
                                     text={"Close"}
                                     onClick={this.props?.handleClose}
                                 />
                             </MDBCol>
                         </MDBRow>
                     </MDBRow>
                 </div>}
            </>
        );
    }
}
export default withUserContext(OnflyLookup);
